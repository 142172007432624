import React from "react"
import { createGlobalStyle } from "styled-components"
import { Link } from "gatsby"

import "remixicon/fonts/remixicon.css"
import "../tailwind.css"
import Logo from "../static/images/TD.svg"
import IconLinkedin from "../static/images/icons/social/linkedin.svg"
import IconTwitter from "../static/images/icons/social/twitter.svg"
import IconGithub from "../static/images/icons/social/github.svg"
import IconTreehouse from "../static/images/icons/social/treehouse.svg"

const GlobalStyle = createGlobalStyle`
:root {
  --color-red-100: #FDECEC;
  --color-red-200: #FAD0CF;
  --color-red-300: #F7B3B3;
  --color-red-400: #F27B79;
  --color-red-500: #EC4240;
  --color-red-600: #D43B3A;
  --color-red-700: #8E2826;
  --color-red-800: #6A1E1D;
  --color-red-900: #471413;

  --color-blue-100: #E7F5FE;
  --color-blue-200: #C3E5FD;
  --color-blue-300: #9FD6FC;
  --color-blue-400: #58B7F9;
  --color-blue-500: #1098F7;
  --color-blue-600: #0E89DE;
  --color-blue-700: #0A5B94;
  --color-blue-800: #07446F;
  --color-blue-900: #052E4A;

  --color-portica-100: #FFFDF0;
  --color-portica-200: #FFFBD9;
  --color-portica-300: #FFF8C2;
  --color-portica-400: #FFF294;
  --color-portica-500: #FFED66;
  --color-portica-600: #E6D55C;
  --color-portica-700: #998E3D;
  --color-portica-800: #736B2E;
  --color-portica-900: #4D471F;

  --color-alto-100: #FBFBFB;
  --color-alto-200: #F5F5F5;
  --color-alto-300: #EFEFEF;
  --color-alto-400: #E4E4E4;
  --color-alto-500: #D8D8D8;
  --color-alto-600: #C2C2C2;
  --color-alto-700: #828282;
  --color-alto-800: #616161;
  --color-alto-900: #414141;

  --color-cove-100: #E7E7EC;
  --color-cove-200: #C2C2CF;
  --color-cove-300: #9E9DB2;
  --color-cove-400: #555478;
  --color-cove-500: #0C0A3E;
  --color-cove-600: #0B0938;
  --color-cove-700: #070625;
  --color-cove-800: #05051C;
  --color-cove-900: #040313;
}
`

export default ({ children }) => {
  var today = new Date()
  var year = today.getFullYear()

  return (
    <div className="layout">
      <GlobalStyle />

      <header className="relative z-10 p-8 bg-white text-center sm:text-left shadow-xl">
        <div className="container mx-auto">
          <div className="flex flex-wrap justify-center md:justify-between items-center">
            <div className="flex-initial">
              <div className="flex flex-wrap flex-col sm:flex-row justify-center md:justify-between items-center">
                <div className="td-logo">
                  <Link to="/">
                    <Logo />
                  </Link>
                </div>
                <div className="flex-initial">
                  <Link to="/" className="no-underline">
                    <p className="text-2xl md:text-4xl font-black text-cove-400 leading-none mb-2 uppercase">
                      Thomas <br />
                      Daugherty
                    </p>
                    <p className="mb-0 text-cove-400">
                      <strong>UI/UX Web Developer</strong> <i> Knoxville, TN</i>
                    </p>
                  </Link>
                </div>
              </div>
            </div>

            <div className="social flex-initial">
              <ul className="grid grid-flow-row grid-cols-4 grid-rows-1">
                <li>
                  <a
                    href="https://www.linkedin.com/in/tdaug/"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Connect with me on Linkedin"
                  >
                    <IconLinkedin />
                  </a>
                </li>
                <li>
                  <a
                    href="https://teamtreehouse.com/z33"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="View my projects on GitHub"
                  >
                    <IconTreehouse />
                  </a>
                </li>
                <li>
                  <a
                    href="https://github.com/z33"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="View my projects on GitHub"
                  >
                    <IconGithub />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/TDaug_"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Follow me on Twitter"
                  >
                    <IconTwitter />
                  </a>
                </li>
                {/* <li>
                  <a
                    href="https://dribbble.com/z33"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="p-4 no-underline"
                  >
                    https://dribbble.com/z33
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </header>

      <div className="content pb-32">{children}</div>

      <footer className="p-8 text-center">
        <div className="container mx-auto">
          <p className="flex justify-center content-center mx-auto">
            <i className="ri-copyright-fill mr-1"></i>{" "}
            <span className="font-medium">
              {year} Thomas Daugherty. All rights reserved.
            </span>
          </p>
        </div>
      </footer>
    </div>
  )
}
